/*
 * Copyright 2023 VMware, Inc.
 * All rights reserved.
 */

@import 'variables';

// ngx-color adds box-shadow to the color picker. This overrides that.
dpa-color-picker {
  color-chrome {
    .chrome-picker {
      background: var(--clr-card-bg-color) !important;
      box-shadow: none !important;

      .chrome-toggle {
        .chrome-icon {
          svg {
            path {
              fill: var(--cds-global-typography-color-200) !important;
            }
            &:hover {
              background: none;
              path {
                fill: var(--cds-global-typography-color-300) !important;
              }
            }
          }
        }
      }

      color-editable-input {
        input {
          background: var(--clr-forms-textarea-background-color) !important;
          color: var(--clr-forms-text-color) !important;
          box-shadow: var(--cds-alias-object-border-color) 0 0 0 px(1) inset !important;
        }
      }
    }
  }

  dpa-accordion {
    .accordion {
      border: none !important;

      .accordion-title {
        padding: px(5) 0 px(5) px(10) !important;
        font-weight: lighter !important;
      }

      .accordion-body {
        padding: 0 px(14) px(14) !important;
      }
    }
  }
}
