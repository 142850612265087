/*
 * Copyright 2023 VMware, Inc.
 * All rights reserved.
 */

@import 'variables';

dpa-global-search clr-tabs ul[role='tablist'] {
  overflow-x: scroll;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
}
