/*
 * Copyright 2017 VMware, Inc.
 * All rights reserved.
 */

@import 'variables';

/* You can add global styles to this file, and also import other style files */
@import 'styles/card';
@import 'styles/clarity-override';
@import 'styles/common';
@import 'styles/content-tooltip';
@import 'styles/dashboards';
@import 'styles/deep-override';
@import 'styles/global-orchestrator';
@import 'styles/getting-started';
@import 'styles/home-page';
@import 'styles/icons';
@import 'styles/images';
@import 'styles/ng-cdk-override';
@import 'styles/sticky-header';
@import 'styles/ngx-chart-overrides';

// spacing-classes contains margin and padding helper classes
@import 'styles/spacing-classes';

// browser specific css
@import 'styles/browser_specific';
