/*
 * Copyright 2020 VMware, Inc.
 * All rights reserved.
 */

/*
 * This styles are global. They allow to style children elements passed to sticky-header.
 *
 */
@import 'variables';

// actions
.sticky-header-actions {
  .btn-group {
    margin: $x1 $x2 $x1 0;
  }

  .dropdown-menu .btn[disabled] {
    cursor: not-allowed;
  }
}

.sticky-header.minimized .sticky-header-actions {
  flex: 1;
  order: 3;
  text-align: right;
  flex-wrap: nowrap;
  div.btn-group-nowrap {
    .btn {
      font-size: x(1.6);
    }
  }
  .col-xs {
    white-space: nowrap;
  }
}

.sticky-header.minimized {
  .hide-when-minimized {
    display: none;
  }
}

// content
.sticky-header.full .sticky-header-content > :first-child:not(.no-margin) {
  margin-top: x(1.5); // doesn't apply margin-top is -content is empty
}

.action-buttons {
  white-space: nowrap;

  clr-dropdown {
    margin-right: $x2;
  }
}

// tabs
.sticky-header-tabs {
  margin-bottom: x(-3.5);

  .tab-data {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .tab-value {
    font-size: x(3.33);
  }

  .active .tab-value {
    font-weight: 500;
  }

  &.minimized {
    flex: 1;

    .active-tab {
      vertical-align: middle;
      font-size: x(2.66);
      font-weight: 500;
      padding-right: $x4;

      &::before {
        content: ':';
        font-weight: normal;
      }
    }

    .tab-data {
      display: inline-flex;
      margin-left: $x1;

      > * {
        font-size: $x2;
        font-weight: normal;
        border: px(1) solid var(--cds-alias-object-border-color);
        border-radius: remToBase(1);
        padding: 0 $x2;
        line-height: x(3.5);
        cursor: default;
      }
    }

    .dropdown-item {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .tab-data {
        margin-left: $x4;
      }
    }
  }
}

dpa-sticky-header .minimized {
  dpa-bread-crumb-viewer {
    width: 100%;
  }
  dpa-sticky-header-tabs {
    margin-bottom: 0;
  }

  .page-title {
    font-weight: 500;
  }
}

dpa-sticky-header.has-tabs .minimized {
  .page-title {
    font-weight: 200;
  }

  .sticky-header-content {
    align-items: center;
  }
}

// breadcrumbs
.sticky-header h2 a {
  color: var(--cds-global-color-construction-600);
}

.text-ellipsis {
  vertical-align: top;
}

.sticky-header h2 > cds-icon {
  margin: 0 $x1;
}

// details
.sticky-header-details {
  margin: $x2 0;

  &.minimized {
    margin-left: $x2;
    margin-top: 0;
    margin-bottom: 0;
  }
}

.sticky-header.minimized .sticky-header-details.subtitle {
  margin-left: remToBase(1);
  margin-top: px(13);
}

// fullscreen mode
body:fullscreen {
  width: 100%; // fix for Safari

  .sidenav {
    display: none;
  }

  dpa-fluid-height.minimized {
    box-shadow: none;
  }
}

// ie11
body:-ms-fullscreen {
  .sidenav {
    display: none;
  }

  dpa-fluid-height.minimized {
    box-shadow: none !important;
  }
}

dpa-sticky-header-v2 {
  dpa-header-meta {
    .header-meta-item {
      &.hide-in-header {
        display: none;
      }
    }
  }
  dpa-modal {
    dpa-header-meta {
      display: block;
      .header-meta-item {
        display: flex !important;
        &.large-dot::after {
          display: none;
        }
        span.header-meta-element {
          font-size: x(2.33) !important;
          font-weight: 400;
          padding-top: px(3);
          padding-bottom: px(3);
          word-break: break-word;
          flex-shrink: 0;
        }
        span.header-label-gap {
          margin-right: 0 !important;
        }
        span.header-label {
          flex-basis: x(34);
          margin-top: 0;
        }
        span.header-value {
          flex-basis: x(94);
        }
      }
    }
  }
}
