/*
 * Copyright 2019 VMware, Inc.
 * All rights reserved.
 */

/**
* Margin and padding classes
*
* Usage:
* m-x0 -> margin: 0;
* m-x1 -> margin: x(1);
* mt-x1 -> margin-top: x(1);
* mb-x1 -> margin-bottom: x(1);
* pl-x5 -> padding-left: x(5); -> padding-left: 1.25rem;
*
* Additionally the below classes (from Bootstrap) are added to fix the templates with margin and padding issues
* mt/r/b/l-0 throgh 10 -> margin-top/right/bottom/left: 0 through x(10) !important
* pt/r/b/t-0 through 10 -> paddin-top/right/bottom/left: 0 through x(10) !important
*
*/
$sides: (top, right, bottom, left);

@for $space from 0 through 10 {
  @each $side in $sides {
    .m#{str-slice($side, 0, 1)}-#{$space} {
      margin-#{$side}: x($space) !important;
    }

    .m#{str-slice($side, 0, 1)}-x#{$space} {
      margin-#{$side}: x($space);
    }

    .p#{str-slice($side, 0, 1)}-#{$space} {
      padding-#{$side}: x($space) !important;
    }

    .p#{str-slice($side, 0, 1)}-x#{$space} {
      padding-#{$side}: x($space);
    }
  }

  .m-x#{$space} {
    margin: x($space);
  }

  .p-x#{$space} {
    padding: x($space);
  }

  .mh-x#{$space} {
    margin-left: x($space);
    margin-right: x($space);
  }

  .mv-x#{$space} {
    margin-top: x($space);
    margin-bottom: x($space);
  }

  .ph-x#{$space} {
    padding-left: x($space);
    padding-right: x($space);
  }

  .pv-x#{$space} {
    padding-top: x($space);
    padding-bottom: x($space);
  }

  .mh-#{$space} {
    margin-left: x($space) !important;
    margin-right: x($space) !important;
  }

  .mv-#{$space} {
    margin-top: x($space) !important;
    margin-bottom: x($space) !important;
  }

  .ph-#{$space} {
    padding-left: x($space) !important;
    padding-right: x($space) !important;
  }

  .pv-#{$space} {
    padding-top: x($space) !important;
    padding-bottom: x($space) !important;
  }
}
