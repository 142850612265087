/*
 * Copyright 2020 VMware, Inc.
 * All rights reserved.
 */

@import 'variables';

/* Mozilla Firefox : Start */
/* stylelint-disable media-feature-name-no-unknown */
@media screen and (min--moz-device-pixel-ratio: 0) {
  /*
   * This FF specific CSS is for blue button outline which is not visible in FF. This is known
   * Clarity framework issue. https://github.com/vmware/clarity/issues/4667
   */
  .btn {
    &.btn-primary:focus,
    &.btn-danger:focus {
      outline: 1px dotted $clr-black;
    }
  }
}
/* stylelint-enable media-feature-name-no-unknown */
/* Mozilla Firefox : End */

/* Safari : Start */
@supports (-webkit-hyphens: none) {
  /*
   * For Apteligent add application modal, safari is not showing outline to buttons and info tooltip.
   */
  dpa-add-apteligent {
    button:focus,
    dpa-tooltip:focus {
      outline-style: solid;
    }
  }
}
/* Safari : End */
