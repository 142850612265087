/*
 * Copyright 2021 VMware, Inc.
 * All rights reserved.
 */

/*
 * This styles are global. These styles are for WizardWithStickyHeaderComponent.
 *
 */
@import 'variables';

.wizard-with-sticky-header {
  .sticky-header {
    padding-top: x(3) !important;
    justify-content: space-between;

    .bread-crumbs-inner {
      flex-direction: row !important;
    }

    .header-actions-template {
      .btn-sm {
        transition: all 200ms;
      }

      button:not(.btn-sm) {
        transition: all 200ms;
      }
    }
  }
  .has-breadcrumbs {
    .sticky-header:not(.minimized) {
      .bread-crumbs-inner {
        flex-direction: column !important;
      }
      .header-actions {
        .header-actions-wrapper-top {
          display: none !important;
        }
        .header-actions-template {
          margin-top: px(10);
        }
      }
    }
  }
}
