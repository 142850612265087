/*
 * Copyright 2017 VMware, Inc.
 * All rights reserved.
 */

@import 'variables';

.bg-image {
  background: {
    repeat: no-repeat;
    position: center center;
    size: contain;
  }

  &.intro-modal-xl {
    width: remToBase(24);
    height: remToBase(19);
  }

  &.deem-purple-book-man {
    background-image: url('../assets/images/deem-purple-book-man.svg');
    width: px(160);
    height: px(120);
  }

  &.deem-purple-book-man-full {
    background-image: url('../assets/images/deem-purple-book-man-full.svg');
    width: px(450);
    height: px(350);
  }

  &.cve-blue-lock-man {
    background-image: url('../assets/images/cve-blue-lock-man.svg');
    width: px(160);
    height: px(120);
  }

  &.cve-blue-lock-man-full {
    background-image: url('../assets/images/cve-blue-lock-man-full.svg');
    width: px(432);
    height: px(350);
  }

  &.intro-modal-dashboards {
    background-image: url('../assets/images/dashboards-overview.svg');
  }

  &.intro-modal-automation {
    background-image: url('../assets/images/automation-overview.svg');
  }

  &.intro-modal-navigation {
    background-image: url('../assets/images/navigation-overview.svg');
  }

  &.intro-modal-trial-form {
    background-image: url('../assets/images/trial-form.svg');
  }

  &.empty-state-img {
    width: auto;
    height: remToBase(15.8333);
    &.template-empty-category {
      height: remToBase(9);
      opacity: 0.5;
    }
    &.empty-state-img-small {
      height: x(20);
    }
  }

  &.automation-empty-state {
    background-image: url('../assets/images/automation-empty-state.svg');
  }

  &.manage-templates-empty-state {
    background-image: url('../assets/images/manage-template-empty-state.svg');
  }

  &.report-empty-state {
    background-image: url('../assets/images/reports-empty-state.svg');
  }

  &.robot-inspect {
    background-image: url('../assets/images/robot-inspect.svg');
  }

  &.widget-empty-state {
    background-image: url('../assets/images/widget-empty-state.svg');
  }

  &.empty-filter-results {
    background-image: url('../assets/images/empty-filter-results.svg');
  }

  &.lego {
    background-image: url('../assets/images/lego.svg');
  }

  &.service-account-empty-state {
    background-image: url('../assets/images/service-accounts-empty-state.svg');
  }

  &.data-access-policy-empty-state {
    background-image: url('../assets/images/data-access-policy-empty-state.svg');
  }

  &.custom-actions-empty-state {
    background-image: url('../assets/images/custom-actions-empty-state.svg');
  }

  &.app-empty-state {
    background-image: url('../assets/images/app-empty-state.svg');
  }

  &.account-group-empty-state {
    background-image: url('../assets/images/account-group-empty-state.svg');
  }

  &.recent-activity-empty-state {
    background-image: url('../assets/images/recent-activity-empty-state.svg');
    margin-left: x(18);
  }

  &.deem-sam-compare-empty-state {
    background-image: url('../assets/images/add_apps.svg');
  }

  &.store-logo {
    height: x(7.83);
    width: x(26.5);
    &[data-device-store='ANDROID'] {
      background-image: url('../assets/icons/vendors/svg/google-play.svg');
    }
    &[data-device-store='IOS'] {
      background-image: url('../assets/icons/vendors/svg/ios-store.svg');
    }
  }

  &.gradient-background-banner {
    position: relative;
    background: linear-gradient(90deg, $dpa-user-profile-blue 25%, $dpa-user-profile-green 90%);
    width: 100%;
    height: px(200);
    &::after {
      position: absolute;
      content: '';
      top: 0;
      bottom: 0;
      right: x(10);
      width: x(58);
      background-image: url('../assets/images/profile-banner-graphic.svg');
    }
  }
}

.access-denied {
  background-size: cover;
  background-image: url('../assets/images/access-denied.svg');
  height: remToBase(13);
  width: remToBase(14);
}

.automation-intro {
  background-image: url('../assets/images/automation-intro.svg');
  width: x(53.5);
  height: x(35);
}

.dashboard-intro {
  background-image: url('../assets/images/dashboard-intro.svg');
  width: x(60);
  height: x(35);
}

.home-page-banner {
  background: url('../assets/images/home-page-banner.svg') no-repeat;
  background-position: right;
  height: x(41.67);
  min-width: x(116.67);
}

.getting-started-banner {
  background: url('../assets/images/getting-started-banner.svg') no-repeat;
  background-position: right;
  height: x(41.67);
  min-width: x(85);
}

.report-intro {
  background-image: url('../assets/images/report-intro.svg');
  width: x(69);
  height: x(35);
}

.service-opt-in-background {
  background: url('../assets/images/service-opt-in-background.svg');
  background-size: contain;
}

.banner-left {
  background-image: url('../assets/images/banner-left.svg');
}

.banner-right {
  background-image: url('../assets/images/banner-right.svg');
}

.risk-dashboard-banner {
  background-image: url('../assets/images/vidm-screens.svg');
}

.session-expired {
  background-image: url('../assets/images/expired.svg');
  background-repeat: no-repeat;
  margin-top: remToBase(1.3005) !important;
  margin-left: remToBase(1.62) !important;
}

.campsite {
  background-image: url('../assets/images/campsite.svg');
}

.screens {
  background-image: url('../assets/images/screens.svg');
}

.access-restricted {
  background-size: cover;
  background-image: url('../assets/images/access-restricted.svg');
  height: x(53);
  width: x(67);
}

.bmar {
  background-size: cover;
  background-image: url('../assets/images/restricted-access.svg');
  height: x(24);
  width: x(51);
}

.trial-ended {
  background-size: cover;
  background-image: url('../assets/images/trial-ended.svg');
  height: x(64);
  width: x(96);
}

.access-request {
  background-size: cover;
  background-image: url('../assets/images/access-request.svg');
  height: x(52);
  width: x(91);
}

.deem-right-triangle {
  background-image: url('../assets/images/deem-right-triangle.svg');
}

.deem-ux-triangle {
  height: x(22);
  width: x(20);
  background-size: 100%;
  background-position: top center;
  background-repeat: no-repeat;

  &.good {
    background-image: url('../assets/images/deem-ux-triangle-good.svg');
  }

  &.neutral {
    background-image: url('../assets/images/deem-ux-triangle-neutral.svg');
  }

  &.poor {
    background-image: url('../assets/images/deem-ux-triangle-poor.svg');
  }
}

.cve-blue-ring {
  width: px(26);
  height: px(26);
  background-image: url('../assets/images/blue-ring.svg');
}

.cve-blue-slash {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: left;
  background-image: url('../assets/images/blue-slash.svg');
}

.robot-gradient {
  background-image: url('../assets/images/robot-gradient.svg');
}

.access-control-users {
  background-image: url('../assets/images/access-control-users.svg');
}

.azure-card-header {
  background: url('../assets/images/azure-card-header.svg') no-repeat;
  background-position: right;
  height: x(39);
}

.cve-detail-setup-sla {
  background-image: url('../assets/images/green-tree-hills.svg');
}

.networking {
  background: center / contain url('../assets/images/networking.svg') no-repeat;
  height: x(25);
  width: x(35);
}

.cve-setup-banner-graphic {
  background-image: url('../assets/images/cve-setup-banner-graphic.svg');
  height: px(183);
  width: px(281);
}

.admins-at-work {
  background: center / contain url('../assets/images/admins-at-work.svg') no-repeat;
  height: x(22.6);
  width: x(41);
}

.biotechnology {
  background: center / contain url('../assets/images/biotechnology.svg') no-repeat;
  height: x(27);
  width: x(41);
}

.cityscape {
  background: center / contain url('../assets/images/cityscape.svg') no-repeat;
  height: x(28);
  width: x(43);
}

.energy-sustainable {
  background: center / contain url('../assets/images/energy-sustainable.svg') no-repeat;
  height: x(25);
  width: x(61);
}

.admins-search {
  background: center / contain url('../assets/images/admins-search.svg') no-repeat;
  height: x(22);
  width: x(30.5);
}

.bob-no-results {
  background: url('../assets/images/bob-no-results.svg') no-repeat;
  height: px(264);
  width: px(340);
}

.light-folders {
  background: url('../assets/images/light-folders.png') no-repeat;
  height: px(204);
  width: px(238);
}

.no-events {
  background: url('../assets/images/no_events.svg') no-repeat;
  height: px(104);
  width: px(146);
}

.add-admins {
  background: url('../assets/images/add_admins.svg') no-repeat;
  height: px(200);
  width: px(305);
}

.no-invite {
  background: url('../assets/images/no_invite.svg') no-repeat;
  height: px(135);
  width: px(156);
}

.man-skyline {
  background: url('../assets/images/man_skyline.png') no-repeat;
  background-size: contain;
  height: 100%;
}

.marketplace-banner {
  background: url('../assets/images/marketplace-banner.svg') no-repeat;
  background-position: center;
  background-size: cover;
  width: 100%;
  height: px(350);
}

.ws1-logo {
  background:
    url('../assets/images/ws1-logo-bg.svg'),
    url('../assets/images/ws1-logo-bg.svg') no-repeat;
  height: x(150);
  width: x(150);
}

.deem-solution-explore {
  background: url('../assets/images/deem-solution-explore.svg') no-repeat;
  background-size: contain;
  background-position: left;
  height: px(447);
  width: px(860);
}

.cve-solution-explore {
  background: url('../assets/images/cve-solution-explore.svg') no-repeat center;
  background-size: contain;
  width: 100%;
  height: px(400);
}

.cve-mkp-banner {
  width: 100%;
  background: url('../assets/images/cve-mkp-banner.svg') no-repeat right;
  background-size: cover;
  height: 100%;
}

.cve-install-status {
  background: url('../assets/images/cve-install-status.svg') no-repeat center;
  background-size: contain;
  width: 100%;
  height: px(378);
}

.cve-sla-patch-target {
  background: url('../assets/images/cve-sla-patch-target.svg') no-repeat center;
  background-size: contain;
  width: 100%;
  height: px(215);
}

.workflow-settings {
  background: url('../assets/images/workflow-settings.svg') no-repeat center;
  background-size: contain;
  width: 100%;
  height: px(203);
}

.deem-scores {
  background: url('../assets/images/deem-scores.svg') no-repeat center;
  background-size: contain;
  width: 100%;
  height: px(400);
}

.score-meter {
  height: px(64);
  width: px(112);

  &.good {
    background: url('../assets/images/deem-meter-good.svg') no-repeat;
  }

  &.poor {
    background: url('../assets/images/deem-meter-poor.svg') no-repeat;
  }

  &.neutral {
    background: url('../assets/images/deem-meter-neutral.svg') no-repeat;
  }

  &.na {
    background: url('../assets/images/deem-meter-na.svg') no-repeat;
  }
}

.four-score-meter {
  height: px(64);
  width: px(112);

  &.good {
    background: url('../assets/images/deem-four-score-meter-good.svg') no-repeat;
  }

  &.fair {
    background: url('../assets/images/deem-four-score-meter-fair.svg') no-repeat;
  }

  &.poor {
    background: url('../assets/images/deem-four-score-meter-poor.svg') no-repeat;
  }

  &.bad {
    background: url('../assets/images/deem-four-score-meter-bad.svg') no-repeat;
  }

  &.na {
    background: url('../assets/images/deem-meter-na.svg') no-repeat;
  }
}

.risk-meter {
  height: px(48);
  width: px(58);
  transform: scale(1.2);

  &.low {
    background: url('../assets/images/risk-low.svg') no-repeat;
  }

  &.high {
    background: url('../assets/images/risk-high.svg') no-repeat;
  }

  &.medium {
    background: url('../assets/images/risk-medium.svg') no-repeat;
  }

  &.na {
    background: url('../assets/images/risk-na.svg') no-repeat;
  }
}

.solution-info-modal-banner {
  background: url('../assets/images/solution-info-modal-banner.svg') no-repeat;
}

.device-info-bars {
  background: url('../assets/images/device-info-bars.svg') no-repeat;
  height: px(106);
  width: px(190);
}

.stacked-vertical-bars {
  background: url('../assets/images/stacked-vertical-bars.svg') no-repeat;
  height: px(106);
  width: px(190);
}

.stacked-horizontal-bars {
  background: url('../assets/images/stacked-horizontal-bars.svg') no-repeat;
  height: px(106);
  width: px(190);
}

.stacked-line-graph {
  background: url('../assets/images/stacked-line-graph.svg') no-repeat;
  height: px(106);
  width: px(190);
}

.csp-account-request-img {
  background: url('../assets/images/csp-account-request.svg');
  height: px(112);
  width: px(151);
}

.csp-account-granted-img {
  background: url('../assets/images/csp-account-granted.svg');
  height: px(112);
  width: px(172);
}

.frontline-enabled-graphic {
  background: url('../assets/images/frontline-enabled.svg');
  height: px(183);
  width: px(272);
}

.learn-more-desktop-deliver-laptop-img {
  background: url('../assets/images/deem-laptop.png') no-repeat center;
  background-size: contain;
  width: 100%;
  height: 100%;
}

.learn-more-itsm-connector-img {
  background: url('../assets/images/deem-itsm-connector.png') no-repeat center;
  background-size: contain;
  width: 100%;
  height: 100%;
}

.deem-learn-more-insights-img {
  background: url('../assets/images/deem-learn-more-insights.png') no-repeat center;
  background-size: contain;
  width: 100%;
  height: 100%;
  aspect-ratio: 2;
}

.deem-learn-more-assist-img {
  background: url('../assets/images/deem-learn-more-assist.png') no-repeat center;
  background-size: contain;
  width: 100%;
  height: 100%;
  aspect-ratio: 2;
}

.learn-more-horizon-deliver-laptop-img {
  background: url('../assets/images/deem-learn-more-horizon-overview.png') no-repeat center;
  background-size: contain;
  width: 100%;
  height: 100%;
}

.deem-virtual-experience-score {
  background: url('../assets/images/deem-virtual-experience-score.png') no-repeat center;
  background-size: contain;
  width: 100%;
  height: 100%;
}

.deem-frontline-intelligent-hub {
  background: url('../assets/images/deem-frontline-intelligent-hub.png') no-repeat center;
  background-size: contain;
  width: 100%;
  height: 100%;
}

.deem-mobile-intelligent-hub {
  background: url('../assets/images/deem-mobile-intelligent-hub.png') no-repeat center;
  background-size: contain;
  width: 100%;
  height: 100%;
}

.data-explorer-no-data {
  background: url('../assets/images/data-explorer-no-data.svg') no-repeat center;
  background-size: contain;
  width: px(200);
  height: px(200);
}

.deem-trial-modal {
  background: url('/assets/images/deem-no-text.png') no-repeat center;
  background-size: cover;
  width: 100%;
  height: 100%;
  aspect-ratio: 2;
}

.intelligence-trial-modal {
  background: url('/assets/images/intelligence-premium-no-text.png') no-repeat center;
  background-size: cover;
  width: 100%;
  height: 100%;
  aspect-ratio: 2;
}

.experience_score_no_data {
  background: url('../assets/images/experience_score_no_data.svg') no-repeat center;
  background-size: contain;
  width: px(140);
  height: px(140);
}

.deem-playbooks-welcome-info {
  width: 100%;
  background: url('../assets/images/deem-playbooks-welcome-info.svg') no-repeat center;
  background-size: cover;
  height: 100%;
}

.deem-playbooks-enable-modal {
  background: url('../assets/images/deem-playbooks-enablement-modal.svg') no-repeat center;
  background-size: cover;
  width: 100%;
  height: 100%;
  aspect-ratio: 2;
}

.insights_no_data {
  background: url('../assets/images/insights_no_data.svg') no-repeat center;
  background-size: contain;
  width: px(140);
  height: px(140);
}

.surveys_no_data {
  background: url('../assets/images/surveys_no_data.svg') no-repeat center;
  background-size: contain;
  width: px(140);
  height: px(140);
}

.investigations_no_data {
  background: url('../assets/images/investigations_no_data.svg') no-repeat center;
  background-size: contain;
  width: px(140);
  height: px(140);
}

.automations_no_data {
  background: url('../assets/images/automations_no_data.svg') no-repeat center;
  background-size: contain;
  width: px(140);
  height: px(140);
}
