/*
 * Copyright 2020 VMware, Inc.
 * All rights reserved.
 */

// prettier-ignore
$z-index: (
'auto': 'auto',
'zero': 0,
'bookmark': 2,
'fluid-height': 10,
'full-page': 11,
'type-ahead-dropdown': 501,
'global-search-dropdown': 2001,
);

@function z-index($key) {
  @return map-get($z-index, $key);
}

@mixin z-index($key) {
  z-index: z-index($key);
}

@mixin z-index-important($key) {
  z-index: z-index($key) !important;
}
