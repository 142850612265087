/*
 * Copyright 2017 VMware, Inc.
 * All rights reserved.
 */

@import 'variables';
@import 'z-index';

/* Styles for dashboards */
.dashboard {
  height: 100%;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  background-color: $clr-near-white;
  position: relative;
  margin: 0 remToBase(-1.6);
  padding: 0 remToBase(1.6);
}

.dashboard-container {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
}

/* stylelint-disable-next-line selector-type-no-unknown */
gridster {
  flex-grow: 1;
  background-color: $clr-near-white;
}

gridster-item {
  @include z-index(auto);
  background: $clr-near-white;
  box-shadow: none;
}

dpa-create-edit-widget,
dpa-active-widget-modal {
  height: remToBase(0.041);
}

.spinner-container {
  display: flex;
  justify-content: center;
}

.platform-icon {
  height: remToBase(1.3);
}

.android {
  width: remToBase(1.05);
}

.apple {
  width: remToBase(1.175);
}

.windowsphone8,
.winrt,
.windowsmobile,
.windowspc,
.windowsphone {
  width: remToBase(1.3);
}

.dashboard-header {
  display: flex;
  align-items: center;
}

.bookmark-wrapper {
  position: absolute;
  cursor: pointer;
  top: $x2;
  right: $x2;
  color: $clr-gray;
  .is-solid {
    color: $clr-action-blue;
  }
}

.widget-dropdown-indicator-wrapper {
  position: absolute;
  cursor: pointer;
  top: $x2;
  right: $x2;
  cds-icon.dropdown-indicator {
    color: $clr-gray;
  }
}
