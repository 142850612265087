/*
 * Copyright 2024 VMware, Inc.
 * All rights reserved.
 */

@import 'variables';

dpa-global-header {
  dpa-local-search {
    input[type='text'] {
      color: var(--cds-global-color-white) !important;
    }
  }
}
