/*
 * Copyright 2021 VMware, Inc.
 * All rights reserved.
 */

@import '../../../../../node_modules/@dpa/ui-common/styles/mixins';
@import 'variables';

span.large-dot {
  font: {
    size: remToBase(1.2);
    weight: $clr-font-weight-bold;
  }
  line-height: remToBase(0.5833);
  margin: 0 remToBase(0.2);
  display: inline-block;
  position: relative;
  top: remToBase(0.15);
}

dpa-query-builder .condition button.operator-toggle {
  gap: unset;
}

.hidden {
  display: none !important;
}

.dropdown-menu .btn {
  justify-content: left !important;
}

.card-block .fills-card-block {
  margin: 0 px(-15) px(-15) !important;
}

.insights-list-filter-component {
  margin-left: unset !important;
}

.signpost-trigger {
  align-items: center;

  cds-icon[shape='angle'] {
    color: unset !important;
  }
}

.btn-row .last-commented-wrapper {
  padding-top: px(20) !important;
}

dpa-deem-incident-comments {
  quill-editor + .actions {
    margin-top: px(-4) !important;
  }
}

dpa-deem-synthetic-url-monitoring {
  .header-actions-wrapper-bottom {
    .filter-container {
      display: flex;
      align-items: center;

      .quick-filters {
        margin: 0;
      }
    }
  }
}

clr-datagrid.footermode .datagrid-footer {
  border-top-color: var(--clr-table-footer-border-top-color) !important;

  .column-selector .column-selector-button {
    margin: 0 !important;
  }

  .pagination-size {
    height: 100%;
  }

  clr-dg-page-size {
    > label > button {
      margin-block: 0 !important;
    }
  }
}

.automation-details-selected-component .automation-details-header .actions-dropdown {
  padding-right: 0 !important;
}

dpa-deem-expandable-card {
  .control-button {
    width: px(28) !important;
    height: px(28) !important;
  }
}

.dashboard-filter-container .filter-group-container .filter-group-root .filter-group-text-view-container {
  border-bottom: none !important;
}

.multi-range-slider input[type='range'] {
  border: none;
}

.slide-over-content-header {
  dpa-custom-tag {
    .banner-wrapper::after {
      border-right-color: var(--cds-alias-object-container-background-shade);
    }
  }
}

.type-ahead-dropdown {
  .action-buttons {
    border-bottom: none !important;
    padding-inline: px(10);
  }
}
