/*
 * Copyright 2018 VMware, Inc.
 * All rights reserved.
 */

@import 'variables';

// .content-tooltip can only grow as big as its container.
// this extra wrapper allows it to grow bigger than the edit filters button for instance
.content-tooltip-box {
  position: absolute;
  top: remToBase(1.4);
  right: 0;
  width: remToBase(15);
}

.content-tooltip {
  position: absolute;
  background: $clr-white;
  border: remToBase(0.0416) solid $clr-light-midtone-gray;
  border-radius: remToBase(0.125);
  padding: remToBase(0.5) remToBase(1);
  white-space: normal;

  .content-tooltip-tip {
    position: absolute;
    top: 0;
    left: 50%;

    &::before {
      $outer-tip-width: remToBase(0.4167);
      content: '';
      position: absolute;
      width: 0;
      height: 0;
      border-width: $outer-tip-width;
      border-style: solid;
      border-color: transparent transparent $clr-light-midtone-gray transparent;
      top: $outer-tip-width * 2 * -1;
      left: $outer-tip-width * -1;
    }

    &::after {
      $inner-tip-width: remToBase(0.3334);
      content: '';
      position: absolute;
      width: 0;
      height: 0;
      border-width: $inner-tip-width;
      border-style: solid;
      border-color: transparent transparent $clr-white transparent;
      top: $inner-tip-width * 2 * -1;
      left: $inner-tip-width * -1;
    }
  }

  &.bottom-right {
    right: 0;

    .content-tooltip-tip {
      left: auto;
      right: remToBase(2);
    }
  }

  &.bottom-left {
    left: 0;

    .content-tooltip-tip {
      right: auto;
      left: remToBase(2);
    }
  }

  dpa-filter-group-text-view {
    display: inline;
  }

  .operator {
    margin: 0 remToBase(0.125);
  }
}

.content-tooltip-container {
  .content-tooltip {
    opacity: 0;
    pointer-events: none;
    margin-top: remToBase(-1);
    transition: all 200ms ease-in-out;
  }

  &:hover .content-tooltip {
    pointer-events: auto;
    opacity: 1;
    margin-top: 0;
  }
}
