/*
 * Copyright 2020 VMware, Inc.
 * All rights reserved.
 */

@import 'variables';

ngx-tooltip-content {
  &.ngx-charts-tooltip-content {
    &.type-tooltip {
      background-color: $clr-white;
      color: $clr-black;
      border: px(1) solid $clr-light-midtone-gray;
      box-shadow: 0 0 $x5 $clr-light-midtone-gray;

      .tooltip-caret {
        border: none !important;
      }
    }
  }
}

// data-labels on vertical and horizontal charts don't completely fit into the svg
// This workaround lets those labels overflow
// For pie charts, the labels can extend too far out of the svg, so just apply to bar charts for now.
dpa-standard-chart {
  ngx-charts-bar-vertical-stacked,
  ngx-charts-bar-vertical,
  ngx-charts-bar-horizontal-stacked,
  ngx-charts-bar-horizontal {
    svg.ngx-charts {
      overflow: visible;
    }
  }
}
