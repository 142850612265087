/*
 * Copyright 2023 VMware, Inc.
 * All rights reserved.
 */

@import 'variables';

dpa-tree-view.global-header {
  .clr-treenode-content {
    .content-wrapper {
      color: var(--clr-dropdown-item-color);

      &:hover {
        .matching-text {
          color: var(--clr-dropdown-item-color);
        }
      }
    }
  }

  .clr-treenode-caret {
    &:hover {
      color: var(--clr-dropdown-item-color);
    }
  }
}

clr-tree {
  &.tree-view {
    .clr-tree-node-content-container,
    .clr-treenode-checkbox,
    .clr-treenode-checkbox > label {
      width: 100%;
    }

    .clr-tree-node-content-container {
      height: px(30);

      .clr-treenode-content,
      .clr-treenode-checkbox {
        padding-top: 0 !important;
        display: flex;
        height: 100%;
        align-items: center;
        line-height: px(30);
      }

      .clr-treenode-caret {
        flex-basis: px(28);
      }

      .clr-treenode-checkbox {
        padding-left: 0;
      }
    }

    &.no-expand {
      .clr-treenode-content:first-child {
        padding-left: x(1) !important;
      }
    }
  }
}

dpa-tree-view.global-header .clr-tree-node-content-container:hover {
  .clr-treenode-content {
    background-color: var(--cds-global-color-construction-600) !important;
  }
}

clr-tree.tree-view .clr-tree-node-content-container:hover {
  .clr-treenode-content {
    background-color: var(--clr-tree-node-content-bg-hover-color);
    border-radius: var(--clr-tree-border-radius);
  }
}
