/*
 * Copyright 2019 VMware, Inc.
 * All rights reserved.
 */

// Clarity Mixins

// Our Custom Mixins

@mixin flex-and-center {
  display: flex;
  align-items: center;
}

@mixin fill-parent {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

@mixin setIconColor($cds-icon-color) {
  fill: $cds-icon-color;
  .transparent-fill-stroke {
    stroke: $cds-icon-color;
  }
}

@mixin overflow-ellipsis() {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
