/*
 * Copyright 2019 VMware, Inc.
 * All rights reserved.
 */

@import '../../../../../node_modules/@dpa/ui-common/styles/clarity-colors-v2';
@import '../../../../../node_modules/@dpa/ui-common/styles/variables';

/* stylelint-disable color-no-hex */
$session-timeout-countdown: #4e56b8;
$csp-panel-background-color: #25333d;
$csp-panel-header-color: #d9e4ea;
$csp-panel-border-color: #505b63;

$clr-dark-jewel-green: #00bfa9;
$clr-ratio-indicator-red: #f54f47;

$dpa-alto: #d7d7d7;
$dpa-aqua-haze: #ecf1f4;
$dpa-card-selected-bg-color: #d9e4ea;
$dpa-card-selected-border-color: #eee;
$dpa-iron: #dadce0;
$dpa-learn-more-green: #007e7a;
$dpa-learn-more-green-lighter: #00968b;
$dpa-link-water: #daebf8;
$dpa-lochmara: #007cbb;
$dpa-status-green: #62a420;
$dpa-user-profile-blue: #0182b4;
$dpa-user-profile-green: #05b073;
$dpa-action-blue-lightest: #f3f6fa;
$dpa-light-orange: #ffcd97;
$dpa-light-blue: #defff9;
$dpa-light-purple: #b7bde7;
$dpa-purple: #4d007a;
$dpa-lighter-purple: #9b56bb;
$dpa-red: #e62700;
$dpa-automator-blue: #0f1e82;
$dpa-moderator-blue: #014d8a;
$dpa-auditor-blue: #0181a7;
$dpa-ice-blue: #6ddbeb;
$dpa-pale-blue: #f3f6fa;
$dpa-critical-red: #c92100;
$dpa-warning-orange: #f38b00;
$dpa-success-green: #48960c;
$dpa-matching-text: #a3edf6;
$dpa-azure-white: #d9e4ea;
$dpa-action-button-hover: #e0f0f6;
$dpa-action-button-disabled: #666;
$dpa-light-gray: #adbbc4;
$dpa-shark: #202124;
$dpa-shuttle-gray: #5f6368;
$dpa-slider-red: #e12200;
$dpa-slider-yellow: #edb200;
$dpa-solution-header-blue: #1b2a32;
$dpa-solution-header-lighter-blue: #22343c;
$dpa-solution-header-lightest-blue: #354751;
$dpa-solution-header-darker-blue: #0f171c;
$dpa-row-selected: #d8e3e9;
$dpa-stoplight-green: $clr-color-success-500;
$dpa-stoplight-yellow: $clr-color-warning-500;
$dpa-stoplight-red: $clr-color-danger-500;
$dpa-darkheader-view-button: #49afd9;
$dpa-wild-sand: #f5f5f5;
$dpa-filter-tag-border: #747474;
$dpa-impact-gray: #c4c4c4;

$dpa-panel-active-color: $dpa-card-selected-bg-color;
$dpa-panel-inactive-color: #f8f8f8;

$dpa-unread-notification-background: #303f4d;
$dpa-notification-title: #e9ecef;
$dpa-notification-icon-warning: #ffc700;
$dpa-notification-icon-danger: #c02012;
$dpa-notification-banner-icon-info: #00567a;
$dpa-notification-banner-icon-warning: #8f5a00;
$dpa-notification-banner-icon-danger: #991700;

$dpa-nested-filter-and-color: #d9e4ea;
$dpa-nested-filter-and-lines-color: #a6c8da;
$dpa-nested-filter-and-text-color: #4a768e;
$dpa-nested-filter-or-color: #e6e2f0;
$dpa-nested-filter-or-lines-color: #b9a7e5;
$dpa-nested-filter-or-text-color: #7767af;

$dpa-marketplace-gradient-dark-blue: #353baa;
$dpa-marketplace-gradient-light-blue: #05787f;

$dpa-workflow-task-filters-count-color: #798893;
$dpa-workflow-task-actions-count-color: #0179b8;
$dpa-workflow-task-groups-count-color: #62a421;
$dpa-workflow-task-conditions-count-color: #eab072;

$dpa-marketplace-purple: #ad73c8;
$dpa-marketplace-orange: #ff9c32;
$dpa-marketplace-blue: #49afd9;
$dpa-marketplace-purple-gradient: linear-gradient(90deg, #efe3f8 0%, #e1c9f1 30%, transparent 100%);
$dpa-marketplace-orange-gradient: linear-gradient(90deg, #fcf3e9 0%, #ffe5c9 30%, transparent 100%);
$dpa-marketplace-blue-gradient: linear-gradient(90deg, #f2f8fa 0%, #e1f1f6 30%, transparent 100%);
$dpa-marketplace-link-box-gradient: linear-gradient(90deg, #0687d5 0%, #8a3ebb 100%);

$dpa-box-shadow: #00000036;

$dpa-header-color: #002538;

$dpa-go-tag-if-color: #fce8d3;
$dpa-go-tag-then-color: #cde5d1;
$dpa-go-tag-else-color: #e6e2f0;
$dpa-go-canvas-node-selected-bg-color: #f0f3f5;
$dpa-go-canvas-node-selected-border-color: #0079b8;
$dpa-go-condition-node-selected-bg-color: #fff8f0;
$dpa-go-condition-node-hover-color: #fdf3e8;
$dpa-go-group-action-node-hover-color: #f5f5f5;

/* stylelint-enable */

// breakpoints
// Tablets and small desktops
$screen-md-max: 992px; // stylelint-disable-line meowtec/no-px
// Large tablets and desktops
$screen-lg-max: 1200px; // stylelint-disable-line meowtec/no-px
