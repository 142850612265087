/*
 * Copyright 2020 VMware, Inc.
 * All rights reserved.
 */

@import 'variables';

.begin-with-the-basics,
.integrated-service-detail {
  .card-block {
    padding: $x6 $x5;
  }
  .card-title {
    font-size: px(22);
    font-weight: normal;
    margin-bottom: 0;
  }
}
