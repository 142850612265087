/*
 * Copyright 2020 VMware, Inc.
 * All rights reserved.
 */

.list-group-container {
  .list-group-item {
    // show a pointer (hand) when the user tries to move the item in the list
    &.active {
      cursor: pointer;

      //show a move cursor when the user is moving the selected item to it's destination
      &.cdk-drag-placeholder {
        cursor: move;
      }
    }
  }
}

// @angular/cdk/drag-drop adds an outline to 'modal-body' when an item in the list is selected, the below override removes it
.modal-body {
  &:focus {
    outline: none;
  }
}

.cdk-virtual-scroll-orientation-vertical {
  .cdk-virtual-scroll-content-wrapper {
    max-width: 100%;
    min-height: 100%;
  }
}
