/*
 * Copyright 2020 VMware, Inc.
 * All rights reserved.
 */

@import 'variables';
@import 'quill/dist/quill.snow.css';

// Toolbar Clarity Icons styling

/* stylelint-disable no-descending-specificity */
.ql-snow .ql-picker,
.ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-label {
  color: var(--cds-alias-typography-color-200);
}

.clr-select .ql-picker-label svg {
  display: none;
}

.ql-formats {
  margin-right: $x1 !important;

  > button {
    display: flex !important;
    align-items: center;
  }

  cds-icon:not([shape='caret']) {
    height: px(20) !important;
    width: px(20) !important;
    color: var(--cds-alias-typography-color-300) !important;
    background-color: inherit !important;
  }
}

.ql-editor {
  min-height: x(40);
}

// Overrides

.ql-snow {
  .ql-tooltip {
    left: 0 !important;
  }

  .ql-picker-label {
    padding-left: 0;
  }

  .ql-picker.ql-size {
    width: x(12) !important;
  }

  .ql-picker.ql-font {
    width: x(16) !important;

    .ql-picker-label[data-value='serif']::before,
    .ql-picker-item[data-value='serif']::before {
      content: 'Serif';
      font-family: serif;
    }

    .ql-picker-label[data-value='monospace']::before,
    .ql-picker-item[data-value='monospace']::before {
      content: 'Monospace';
      font-family: monospace;
    }
  }
}

.ql-container,
.ql-toolbar {
  &.ql-snow {
    border-color: var(--cds-alias-object-border-color);
    position: relative;
  }
}

.ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-label {
  border-color: var(--cds-alias-object-interaction-border-color);
}

.ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-options {
  border-color: var(--clr-dropdown-border-color);
  background: var(--clr-dropdown-bg-color);
}

.ql-snow .ql-picker-options {
  padding-inline: 0 !important;
  .ql-picker-item {
    padding-inline: px(4);

    &:hover {
      color: var(--clr-dropdown-item-hover-color);
      background: var(--clr-dropdown-item-hover-bg-color);
    }
  }
}

.ql-editor.ql-blank::before {
  color: var(--cds-alias-typography-disabled);
}

.ql-formats.toggle {
  position: absolute;
  right: 0;
  height: 100%;
  bottom: 0;
  display: flex;
  align-items: center;
  background: var(--cds-alias-object-interaction-background-active);
  border-left: px(1) solid var(--cds-alias-object-border-color);
  width: px(16);
  button {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    height: 100%;
    width: 100%;
    padding-inline: px(2);
    cds-icon {
      height: px(14) !important;
      width: px(14) !important;
      margin: 0 !important;
    }
  }
}

.ql-toolbar.ql-snow {
  padding-right: px(20);
}

.ql-snow .ql-icon-picker .ql-picker-label {
  display: flex !important;
  align-items: center;
}

/* stylelint-enable no-descending-specificity */
