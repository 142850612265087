/*
 * Copyright 2019 VMware, Inc.
 * All rights reserved.
 */

@import 'variables';
@import '@danielmoncada/angular-datetime-picker/assets/style/picker.min.css';

// stylelint-disable

.owl-dt-calendar-table {
  .owl-dt-calendar-cell-content {
    font-size: remToBase(0.55);
    border-radius: remToBase(0.125);
  }

  .owl-dt-calendar-cell-selected {
    background-color: var(--clr-calendar-active-cell-background-color);
    &.owl-dt-calendar-cell-today {
      box-shadow: none;
    }
  }

  .owl-dt-calendar-header .owl-dt-weekdays th {
    font-size: remToBase(0.55);
  }

  .owl-dt-calendar-cell {
    &:hover {
      border-radius: remToBase(0.125);
    }
  }
}

.owl-dt-inline-container {
  width: remToBase(11.6);
  line-height: $x4;
  box-shadow: none;

  .owl-dt-calendar {
    height: x(52);
  }
}

.owl-dt-calendar-control {
  color: var(--clr-calendar-btn-picker-trigger-color);
  font-size: $x3;
}

.owl-dt-control-arrow-button svg {
  fill: var(--cds-alias-object-interaction-border-color);
}

.owl-dt-timer-content .owl-dt-timer-input {
  font-size: remToBase(0.8);
  border: none;
}

.owl-dt-timer-divider {
  height: 51%;
  top: remToBase(1.3);

  &::after,
  &::before {
    width: remToBase(0.2);
    height: remToBase(0.2);
  }
}

.owl-dt-timer-box {
  height: 69%;
  width: 42%;
}

.time-picker {
  .owl-dt-inline-container {
    width: remToBase(6.6);
    line-height: remToBase(1);
    box-shadow: none;
  }
}

.owl-dt-timer {
  height: x(20);
}

.owl-dt-calendar-table .owl-dt-calendar-cell-disabled {
  pointer-events: none;
}

.owl-dt-container {
  background-color: var(--clr-calendar-background-color);
}

.date-time-picker {
  background-color: var(--clr-calendar-background-color) !important;

  .pickers .calendar {
    border-right-color: var(--clr-calendar-border-color) !important;
  }

  .controls {
    border-top-color: var(--clr-calendar-border-color) !important;
    background-color: var(--clr-calendar-background-color) !important;
    .time-picker {
      background-color: var(--clr-calendar-background-color);
    }
    .timezone {
      color: unset !important;
    }
  }

  .owl-dt-control-button-arrow svg {
    fill: var(--cds-alias-object-interaction-border-color);
  }
}

owl-date-time-month-view table {
  thead,
  td {
    color: unset !important;
  }
}

.owl-dt-calendar-table .owl-dt-calendar-cell:hover {
  background-color: var(--clr-calendar-btn-hover-focus-color);
  color: var(--clr-calendar-btn-hover-focus-color-text);
}

.owl-dt-calendar-table .owl-dt-calendar-header .owl-dt-calendar-table-divider:after {
  background: var(--clr-calendar-border-color);
}

.owl-dt-timer-content .owl-dt-timer-input {
  background: none !important;
  color: var(--clr-forms-text-color);
}

.owl-dt-calendar-table .owl-dt-calendar-cell-today:not(.owl-dt-calendar-cell-selected) {
  border-color: var(--cds-alias-object-border-color);
}

.owl-dt-calendar-table .owl-dt-calendar-cell-disabled > .owl-dt-calendar-cell-content:not(.owl-dt-calendar-cell-selected) {
  color: var(--clr-forms-text-disabled-color) !important;
  opacity: 0.5;
}

.owl-dt-container-info .owl-dt-container-info-active {
  color: var(--cds-global-color-blue-700);
}

.owl-dt-calendar-multi-year-view,
.owl-dt-calendar-year-table {
  table thead,
  .owl-dt-calendar-cell {
    color: var(--clr-calendar-btn-active-color) !important;
  }
}

.owl-dt-timer-divider {
  &:before,
  &:after {
    width: px(4);
    height: px(4);
  }
}
