/*
 * Copyright 2017 VMware, Inc.
 * All rights reserved.
 */

@use 'sass:math';

@import '../../../../../node_modules/@dpa/ui-common/styles/mixins';
@import 'variables';

// Our Custom Mixins

@mixin tab-container {
  display: flex;
  align-items: flex-end;
  overflow: auto;
  .tab {
    flex: 0 1 auto;
    transition: box-shadow 0.1s ease-in;
    cursor: pointer;

    .hidden-tab-threshold {
      display: none;
    }

    &:not(:last-child) {
      margin-right: x(2);
    }
    &.selected {
      box-shadow: inset 0 x(-0.5) 0 $clr-action-blue;
      .hidden-tab-threshold {
        display: block;
      }
    }
    &:hover:not(.selected) {
      box-shadow: inset 0 x(-0.5) 0 $clr-action-blue-light;
    }
  }
}

@mixin leaderboards-container {
  display: flex;
  overflow-x: auto;
  flex-wrap: nowrap;
  dpa-leaderboard {
    flex: 1 1 0;
    &:not(:first-child) {
      border-left: px(1) solid $clr-light-gray;
    }
  }
}

@mixin metric-percent-tab {
  display: flex;
  align-items: flex-end;
  overflow-x: hidden;
  .metric-percent {
    flex: 0 1 auto;
    width: x(28);
    &:not(:last-child) {
      margin-right: remToBase(1);
    }
    .metric-value {
      font-size: x(3);
      font-weight: 500;
    }
    .date-value {
      font-size: x(2.25);
      font-weight: 500;
    }
  }
}

@mixin flex-and-center {
  display: flex;
  align-items: center;
}

@mixin fill-parent {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

@mixin setIconColor($cds-icon-color) {
  fill: $cds-icon-color;
  .transparent-fill-stroke {
    stroke: $cds-icon-color;
  }
}

@mixin main-section-container {
  display: flex;
  .main-section {
    flex: 1 1 0;
    padding: x(4) x(4) x(8) x(6);
    overflow-x: hidden;
  }
}

@mixin quick-filters-dropdown {
  width: fit-content;
  cursor: pointer;
  font-size: px(11);
  display: flex;
  margin: 0 0 $x1 $x1;
  height: px(21);
  &.quick-filters-label {
    font-weight: 500;
  }
}

@mixin secondary-nav-menu {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  padding-bottom: $x7;
  .menu-items {
    border-bottom: px(1) solid $clr-light-midtone-gray;
    overflow-x: hidden;
    overflow-y: auto;
    padding-bottom: $x3;
  }
}

@mixin secondary-menu-nav-group {
  margin-top: $x2;
  label {
    display: flex;
    align-items: center;
    height: $x6;
    padding-left: $x4;
    position: relative;
    cds-icon {
      color: $clr-gray;
      &:first-child {
        margin-right: x(1.5);
        min-width: px(16);
      }
      &:last-child {
        position: absolute;
        right: x(1.5);
        min-width: px(10);
      }
    }
    &::after {
      content: none;
    }
    &:hover {
      background: $clr-light-midtone-gray;
    }
  }
  &.collapsed {
    label {
      padding-left: px(16);
      cds-icon {
        margin-right: 0;
        &:last-child {
          right: x(0.5);
        }
      }
    }
  }
  .nav-list {
    padding: 0;
    li {
      margin-top: x(0.5);
      .nav-link {
        display: flex;
        align-items: center;
        color: $clr-dark-gray;
        height: $x6;
        line-height: $x6;
        overflow-wrap: break-word;
        padding: 0 0 0 $x8;
        &.active {
          background: $clr-white;
        }
        &:hover {
          background: $clr-light-midtone-gray;
        }
      }
    }
  }
}

@mixin input-number-without-spin-button {
  input[type='number'] {
    // override the appearance so that the arrows for input[type='number'] are not shown
    --moz-appearance: textfield;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }
}

@mixin ghost-text {
  @for $width from 5 through 100 {
    @if $width % 5 == 0 {
      .ghost-#{$width} {
        width: math.percentage(math.div($width, 100));
        display: block;
        height: x(2);
        border-radius: px(6);
        background-color: $clr-color-neutral-300;
        margin: x(2) 0;

        &.lighter {
          background-color: $clr-color-neutral-200;
        }
      }
    }
  }
}

@mixin circle-with-text($borderColor: $clr-blue, $color: $clr-action-blue-dark, $bgColor: $clr-color-action-100) {
  width: $x4;
  height: $x4;
  line-height: x(3.8);
  border-radius: 50%;
  border: px(1) solid $borderColor;
  color: $color;
  background-color: $bgColor;
  text-align: center;
  text-transform: uppercase;
  margin-right: $x1;
  font-size: x(1.6);
}

@mixin nav-group() {
  position: relative;
  align-items: normal;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  .nav-list {
    width: 100%;
  }
}

@mixin crumb-extra-info {
  .crumb-extra-info {
    font-size: x(2);
    color: $clr-dark-midtone-gray;
    display: flex;
    align-items: center;
    margin-left: x(8);
    margin-top: x(1);
    .extra-info-pair {
      flex: 1 1 0;
      display: flex;
      align-items: center;
      max-width: fit-content;
      white-space: nowrap;
      > div:first-child {
        font-weight: bold;
      }
      &:not(:first-child) {
        margin-left: x(2);
      }
      :last-child {
        margin-left: x(1);
      }
    }
  }
}

@mixin threshold-tooltip-container {
  background-color: $clr-white;
  color: $clr-black;
  margin: px(-4) px(-10);
  padding: px(24);
}

@mixin lg {
  @media (max-width: #{$screen-lg-max}) {
    @content;
  }
}
