/*
 * Copyright 2019 VMware, Inc.
 * All rights reserved.
 */

// layout-variables contains x(), px(), and associated variables
@import 'layout-variables';

$clr-font-weight-light: 200 !default;
$clr-font-weight-regular: 400 !default;
$clr-font-weight-semibold: 500 !default;
$clr-font-weight-bold: 600 !default;
$clr-font-weight-extrabold: 600 !default;

// Color for text that matches a fuzzy search
$dpa-fuzzy-match-color: rgb(0, 121, 184);

// @deprecated in 2.0 $clr-font-weights could not be removed because it is public
// in the overwrites file. it had to be deprecated first
// prettier-ignore
$clr-font-weights: (
light: $clr-font-weight-light,
regular: $clr-font-weight-regular,
semibold: $clr-font-weight-semibold,
bold: $clr-font-weight-bold
) !default;

// Other variables
$set-to-front: 10 !default;
$set-to-back: 1 !default;
$set-to-visible: visible !important;
