/*
 * Copyright 2024 VMware, Inc.
 * All rights reserved.
 */

// stylelint-disable no-descending-specificity
// stylelint-disable no-duplicate-selectors

@import '../../../../../node_modules/@dpa/ui-common/styles/mixins';
@import 'variables';

body[cds-theme='dark'] {
  .ngx-json-viewer {
    --ngx-json-key: var(--cds-global-color-aqua-600);
  }
  .nav-text {
    .menu-item-content {
      dpa-ellipsis-with-tooltip.bold {
        color: unset !important;
        font-weight: 400;
      }
    }
  }

  .clr-vertical-nav {
    .nav-group-content,
    .nav-link {
      &:hover {
        background-color: var(--clr-vertical-nav-active-bg-color);
      }
    }
  }

  .main-container {
    background: var(--clr-global-app-background) !important;
    .content-area {
      background: unset !important;
    }
  }

  .homepage-section-panel .panel .homepage-section-label {
    color: unset !important;
  }
  .home-page-footer {
    border-block-color: var(--cds-alias-object-border-color) !important;
  }

  .service-card .card-footer {
    background: unset !important;
  }

  .noThumbnail {
    background-color: unset !important;
    &:hover {
      background-color: var(--cds-alias-object-interaction-background-active) !important;
    }
  }

  .thumbnail:hover {
    background: var(--cds-alias-object-interaction-background-active) !important;
  }

  .card-block .card-text-container {
    color: unset !important;
  }

  .bookmark-cards .card .card-block .card-text {
    color: unset !important;
  }

  .getting-started.show-bg {
    background-color: var(--clr-card-bg-color);
    box-shadow: var(--clr-card-box-shadow);
    border: px(1) solid var(--clr-card-border-color);
  }

  .getting-started .link-wrapper a {
    background-color: unset !important;
    border-color: var(--cds-alias-object-border-color) !important;
    color: var(--cds-alias-typography-color-300) !important;
  }

  .footer-title {
    color: unset !important;
  }

  .list-header {
    color: var(--cds-alias-typography-color-200) !important;
  }

  .footer-link.btn.btn-link {
    color: var(--cds-alias-utility-gray) !important;
  }

  home-page-footer {
    border-block-color: var(--cds-alias-object-border-color) !important;
  }

  .home-page-content .cdk-drop-list .cdk-drag-wrapper .cdk-drag .section-drag-item-container .section-drag-wrapper {
    border-color: var(--cds-alias-object-border-color) !important;
    background-color: var(--clr-card-bg-color) !important;
    box-shadow: var(--clr-card-box-shadow) !important;

    .section-title {
      color: unset !important;
    }
  }

  .page-wrapper .query-search-container .search-wrapper {
    border-color: var(--cds-alias-object-border-color) !important;
    background-color: unset !important;
  }

  as-split-area:first-of-type {
    background-color: unset !important;
  }

  .as-horizontal > .as-split-gutter {
    background-color: var(--cds-global-color-construction-800) !important;
  }

  .page-wrapper .query-search-container {
    border-color: var(--cds-alias-object-border-color) !important;
  }

  .select-data-source {
    border-color: var(--cds-alias-object-border-color) !important;
  }

  .control-container {
    .selection-type,
    .list-container {
      background-color: var(--cds-global-color-construction-1000) !important;
    }

    .top-border {
      border-top-color: var(--cds-alias-object-border-color) !important;
    }

    .list-container.divider {
      border-right-color: var(--cds-alias-object-border-color) !important;
    }

    .list-item {
      color: unset !important;
      background-color: unset !important;

      &:hover {
        color: var(--clr-dropdown-item-active-color) !important;
        background: var(--clr-dropdown-item-active-bg-color) !important;
      }
    }
  }

  .change-data-source {
    border-bottom-color: var(--cds-alias-object-border-color) !important;
  }

  .filter-group-root .rule-group-root {
    background-color: unset !important;
  }

  .filter-group-text-view {
    background: unset !important;
  }

  ul.rule-set > li .rule-number {
    color: unset !important;
    background: unset !important;
    border-right-color: var(--cds-alias-object-border-color) !important;
  }

  .filter-group-root .filter-group-text-view-container {
    background: unset !important;
    border-color: var(--cds-alias-object-border-color) !important;
  }

  .filter-text-actions {
    border-color: var(--cds-alias-object-border-color) !important;

    .action-icon-container {
      background-color: unset !important;
    }
  }

  .section {
    border-color: var(--cds-alias-object-border-color) !important;
  }

  .all-container {
    background-color: var(--clr-dropdown-bg-color) !important;

    .form-group {
      background-color: unset !important;
    }

    .alpha-col-elem-container {
      border-bottom-color: var(--cds-alias-object-border-color) !important;
    }

    .flat-view .columns-header {
      color: unset !important;
      background-color: var(--cds-global-color-construction-600) !important;
    }
  }
  dpa-key-selector clr-tree-node.entity > .clr-tree-node-content-container button.clr-treenode-caret {
    background-color: var(--cds-global-color-construction-600) !important;
    &::after {
      border-left-color: var(--cds-global-color-construction-600) !important;
    }
  }

  .col-items:hover {
    background-color: var(--clr-dropdown-item-active-bg-color) !important;
    color: var(--clr-dropdown-item-active-color) !important;

    .col-item-label:hover {
      background: unset !important;
    }
  }

  dpa-multi-layout-switcher {
    .radio.btn {
      border-color: var(--cds-alias-object-border-color) !important;
      .dpa-icon {
        fill: var(--cds-alias-object-interaction-background) !important;
        color: var(--cds-alias-typography-color-300) !important;

        &.selected {
          fill: var(--cds-alias-object-interaction-background-selected) !important;
        }
      }
    }
  }

  .dashboard-filter-container {
    border-color: var(--cds-alias-object-border-color) !important;
    .filter-group-container {
      border-bottom: none !important;

      .filter-group-root .filter-group-text-view-container {
        border-bottom: none !important;
      }
    }
  }

  .chart-container {
    background: unset !important;
    border-color: var(--cds-alias-object-border-color) !important;
  }
  .horizontal-legend {
    border-color: var(--cds-alias-object-border-color) !important;
  }

  .selectable {
    background-color: unset !important;
    border-color: var(--cds-alias-object-border-color) !important;

    &.selected {
      background: var(--cds-alias-object-interaction-background-active) !important;
    }
  }

  .widget-builder-container {
    .page-wrapper {
      background-color: var(--clr-global-app-background) !important;
    }

    .form-container {
      background-color: unset !important;
      border-color: var(--cds-alias-object-border-color) !important;
    }

    .chart-detail-input {
      border-color: var(--cds-alias-object-border-color) !important;
    }

    .categories {
      border-color: var(--cds-alias-object-border-color) !important;
      .category {
        border-color: var(--cds-alias-object-border-color) !important;
        &.active {
          background-color: var(--cds-alias-object-interaction-background-active);
        }
      }
    }
  }

  .widget-section-container.preview-container {
    background-color: unset !important;
    border-color: var(--cds-alias-object-border-color) !important;

    .widget-section-inner-container {
      background-color: unset !important;
      .preview-container {
        background-color: unset !important;
        border-color: var(--cds-alias-object-border-color) !important;

        .data-type {
          border-color: var(--cds-alias-object-border-color) !important;
        }
      }
    }
  }

  .card.solid-border {
    border-color: var(--clr-card-border-color) !important;
  }

  gridster-item {
    background: unset !important;
  }

  .page-wrapper {
    background-color: var(--clr-global-app-background) !important;
  }

  .section-container .section-inner-container {
    background: unset !important;
    border-color: var(--cds-alias-object-border-color) !important;
  }

  .section-container.preview-container {
    background: unset !important;
    border-color: var(--cds-alias-object-border-color) !important;
  }

  .automation-details-selected-component .automation-details-header {
    background-color: unset !important;
  }

  dpa-automation-details-selected .nav {
    background-color: unset !important;
  }

  .component-workflow {
    background: var(--clr-card-bg-color) !important;
    border-color: var(--clr-card-border-color) !important;
    box-shadow: var(--clr-card-box-shadow) !important;

    p {
      color: unset !important;
    }
    [draggable='true'] {
      border-color: var(--cds-alias-object-border-color) !important;
      color: unset !important;
    }
  }

  .go-canvas-root {
    .canvas-view-container .canvas-view::before {
      background-image: none !important;
      background-color: var(--clr-global-app-background) !important;
    }
  }

  .add-edit-root {
    background-color: var(--clr-global-app-background) !important;
  }

  .go-canvas-root .canvas-view-container .canvas-view::before {
    background-image: none !important;
    background-color: var(--clr-global-app-background) !important;
  }

  dpa-go-canvas-root .go-canvas-root .canvas-view .nodes-container .canvas-node {
    background: var(--clr-card-bg-color) !important;
    color: var(--clr-card-text-color) !important;
    border-color: var(--clr-card-border-color) !important;
    box-shadow: var(--clr-card-box-shadow) !important;
  }

  dpa-go-canvas-root .go-canvas-root .canvas-view .nodes-container .canvas-node:not(.selected):hover {
    background: var(--cds-alias-object-interaction-background-hover) !important;
  }

  dpa-go-canvas-root .canvas-node .node-body .node-sub-label {
    color: var(--cds-alias-typography-color-400) !important;
  }

  dpa-go-canvas-root .canvas-node .node-body {
    color: var(--cds-alias-typography-color-300) !important;
  }

  .header-root {
    .header-left-actions,
    .header-right-actions {
      background: var(--clr-card-bg-color) !important;
      border-color: var(--clr-card-border-color) !important;
      box-shadow: var(--clr-card-box-shadow) !important;
    }
  }

  .go-canvas-node-draggable-overlay {
    background: var(--cds-alias-object-interaction-background-active) !important;
    border-color: var(--clr-global-app-background) !important;
  }

  .panel {
    background: var(--clr-accordion-content-background-color) !important;
    border-color: var(--clr-accordion-border-color) !important;

    .action-panel-header {
      background-color: var(--clr-accordion-header-background-color) !important;
      border-color: var(--clr-accordion-border-color) !important;
    }

    &.expand {
      .action-panel-header {
        border-color: var(--clr-accordion-border-color) !important;
        background-color: var(--clr-accordion-header-active-background-color) !important;
      }
    }

    .textarea {
      background: var(--clr-forms-textarea-background-color) !important;
      color: var(--clr-forms-text-color) !important;
    }

    .text-area {
      border-color: var(--clr-forms-border-color) !important;
    }
  }

  .form-container .test-actions-container {
    border-color: var(--cds-alias-object-border-color) !important;
  }

  .component-workflow [draggable='true']:hover {
    background: var(--cds-alias-object-interaction-background-active) !important;
  }

  .add-step-menu-container {
    ul {
      background: var(--clr-dropdown-bg-color) !important;
      border-color: var(--clr-dropdown-border-color) !important;

      li {
        color: var(--clr-dropdown-item-color) !important;

        &:hover {
          background: var(--clr-dropdown-item-active-bg-color) !important;
          color: var(--clr-dropdown-active-text-color) !important;
        }
      }
    }
  }

  .apteligent-banner {
    background-color: var(--clr-card-bg-color) !important;
    border: solid 1px var(--clr-card-border-color) !important;
  }

  .header-root .header-left-actions .potential-impact {
    color: unset !important;
  }

  .header-root .header-right-actions {
    .workflow-name-container .edit-mode-wrapper {
      background: var(--clr-dropdown-bg-color) !important;
    }

    .workflow-name-container .modal-backdrop {
      background-color: var(--clr-modal-backdrop-color) !important;
    }

    .enable-workflow-label {
      color: unset !important;
    }

    .workflow-name-container .workflow-name {
      color: unset !important;
      &:not(.is-untitled-workflow) {
        color: unset !important;
        border-color: var(--cds-alias-object-border-color) !important;
      }
    }
  }

  .panel .panel-header {
    &.expanded {
      background-color: var(--clr-accordion-header-active-background-color) !important;
    }
    .step-number,
    .step-title .bold-text {
      color: var(--cds-alias-typography-color-200) !important;
    }
  }

  .control-container .list-item {
    border-color: var(--cds-alias-object-border-color) !important;

    .footer-content {
      border-color: var(--cds-alias-object-border-color) !important;
    }
  }
  .trigger-options-container .trigger-option {
    border-color: var(--cds-alias-object-border-color) !important;
  }

  .trigger-options-config-container.bordered {
    border-color: var(--cds-alias-object-border-color) !important;
  }

  .filter-count {
    background: unset !important;
    border-color: var(--cds-alias-object-border-color) !important;
  }

  .go-filter-group .filter-group-text-view {
    border-color: var(--cds-alias-object-border-color) !important;
  }

  dpa-go-template-preview {
    .modal-dialog .modal-content > div:first-child {
      background-color: unset !important;
      border-color: var(--cds-alias-object-border-color) !important;
    }

    .modal-dialog .modal-content > div:last-child {
      border-color: var(--cds-alias-object-border-color) !important;
    }

    h5,
    .text-bold {
      color: var(--cds-alias-typography-color-200) !important;
    }

    .template-info {
      border-color: var(--cds-alias-object-border-color) !important;
    }

    .modal-dialog .modal-content .filter-group-text-view {
      border-color: var(--cds-alias-object-border-color) !important;
    }

    ol .trigger-action {
      border-color: var(--clr-card-border-color) !important;
      background: var(--clr-card-bg-color) !important;
      color: var(--clr-card-text-color) !important;
      box-shadow: var(--clr-card-box-shadow) !important;

      .trigger-action-header .trigger-action-details {
        color: unset !important;
      }
    }
  }

  .go-tag {
    border-color: var(--cds-alias-object-border-color) !important;
    color: var(--cds-alias-typography-color-100) !important;
  }

  .group-panel-header,
  .condition-panel-header {
    background-color: var(--clr-accordion-header-background-color) !important;
    border-color: var(--clr-accordion-border-color) !important;

    &.expanded {
      background: var(--clr-accordion-header-active-background-color) !important;
    }
  }

  .stop-workflow-node,
  .end-condition {
    border-color: var(--cds-alias-object-border-color) !important;
    color: var(--cds-alias-typography-color-100) !important;
  }

  .group-node .action-info {
    background-color: var(--cds-alias-object-container-background-dark) !important;
    border-color: var(--clr-card-border-color) !important;
    box-shadow: var(--clr-card-box-shadow) !important;

    .action-name {
      font-weight: normal !important;
      color: unset !important;
    }
  }

  .branch-summary-wrapper {
    border-color: var(--cds-alias-object-border-color) !important;
  }

  .dashboard-filter-container .date-range-container {
    background: unset !important;

    .filter-icon-container {
      background-color: unset !important;
      color: unset !important;
      border-right: 1px solid var(--cds-alias-object-border-color) !important;
    }
  }

  .dashboard-filter-container .filter-group-container.border-top {
    border-color: var(--cds-alias-object-border-color) !important;
  }

  .separator {
    background-color: var(--cds-alias-object-border-color) !important;
  }

  .go-sidepanel {
    background-color: var(--cds-alias-object-container-background-shade) !important;
    box-shadow: none !important;

    .sidepanel-header {
      background-color: var(--cds-alias-object-container-background) !important;
      border-bottom-color: var(--cds-alias-object-border-color) !important;

      .action-title {
        color: unset !important;
      }

      .group-by-connector label {
        color: unset !important;
      }

      .default {
        border-bottom-color: var(--cds-alias-object-border-color) !important;
      }
    }

    .sidepanel-content {
      .header-content {
        background-color: unset !important;
        border-bottom-color: var(--cds-alias-object-border-color) !important;

        .header-text {
          color: unset !important;
        }
      }

      .service-label {
        color: unset !important;
      }

      .connector-row:hover,
      .action-item:hover {
        background-color: var(--cds-alias-object-interaction-background-active) !important;
      }

      .action-item.active {
        background-color: var(--cds-alias-object-interaction-background-active) !important;
      }
    }
  }

  dpa-chart-selector {
    .btn-group.compact {
      .btn {
        border-color: var(--cds-alias-object-interaction-border-color) !important;
      }
    }
    .btn-primary {
      background-color: var(--cds-alias-object-interaction-background-active) !important;

      cds-icon {
        svg * {
          fill: var(--cds-alias-typography-color-300) !important;
        }
      }
    }
  }

  .view-type-container {
    button:not(.active) {
      border-color: var(--cds-alias-object-interaction-border-color) !important;
    }
  }

  .go-canvas-root {
    .read-mode-sidepanel-backdrop {
      background: var(--clr-modal-backdrop-color) !important;
    }
  }

  .go-canvas-root.read-only-mode {
    .side-panel {
      background: var(--cds-alias-object-container-background-shade) !important;

      ol {
        li {
          background: unset !important;
          border-color: var(--cds-alias-object-border-color) !important;

          .trigger-action-header {
            .trigger-action-details {
              color: unset !important;
            }
            &.expanded {
              border-color: var(--cds-alias-object-border-color) !important;
              background: var(--cds-alias-object-interaction-background-active) !important;
            }
          }
        }
      }
    }
  }

  .custom-footer {
    border-color: var(--cds-alias-object-border-color) !important;
  }

  .leaderboards-container dpa-leaderboard:not(:first-child) {
    border-color: var(--cds-alias-object-border-color) !important;
  }

  // UEM-apps -- start

  .dpa-card {
    background-color: var(--clr-card-bg-color) !important;
    box-shadow: var(--clr-card-box-shadow) !important;
    border-color: var(--clr-card-border-color) !important;

    .dpa-card-title {
      color: unset !important;
    }
  }

  .dashboard-filter-container {
    border-color: var(--cds-alias-object-border-color) !important;

    .filter-group-footer {
      background-color: unset !important;
      border-top: px(1) solid var(--cds-alias-object-border-color) !important;
      margin-top: px(10);
    }

    .filter-group-text-view .filter-text-actions {
      background-color: var(--cds-alias-object-interaction-background);
    }
  }

  .side-section {
    border-color: var(--cds-alias-object-border-color) !important;
  }

  .accordion .accordion-title.selected {
    background-color: var(--clr-accordion-header-active-background-color) !important;
  }

  clr-dg-row-detail {
    background-color: unset !important;
  }

  .crumb-container .crumb-header {
    border-color: var(--cds-alias-object-border-color) !important;
  }

  .uploads-table-container .search-container {
    border-color: var(--cds-alias-object-border-color) !important;
  }
  // UEM-apps -- end

  // UEM-devices -- start
  dpa-device-profile-overview {
    .menu {
      .menu-item {
        border-color: var(--cds-alias-object-border-color) !important;
        background-color: var(--cds-alias-object-interaction-background) !important;

        &.active {
          background-color: var(--cds-alias-object-interaction-background-active) !important;
        }
      }
    }
  }

  // UEM-devices -- end

  // Horizon Cloud - Next Gen - start
  .tabs-container {
    box-shadow: inset 0 px(-1) 0 var(--cds-alias-object-border-color) !important;
    .tab {
      .tab-name {
        color: var(--cds-alias-typography-color-200) !important;
      }
      &.selected {
        .tab-name {
          color: var(--cds-alias-typography-color-300) !important;
        }
      }
    }
  }

  // Horizon Cloud - Next Gen - start

  // Experience Score -- start
  dpa-deem-expandable-card {
    .bg-white {
      background-color: var(--clr-card-bg-color) !important;
    }

    .card-container {
      border-color: var(--cds-alias-object-border-color) !important;

      &:hover {
        background-color: var(--cds-alias-object-interaction-background-hover) !important;
      }

      .card-content {
        &.active {
          background-color: var(--cds-alias-object-interaction-background-active) !important;
        }
      }

      .info .title {
        color: unset !important;
      }
    }
  }

  .page-container .vertical-divider {
    border-color: var(--cds-alias-object-border-color) !important;
  }

  .horizontal-divider {
    border-color: var(--cds-alias-object-border-color) !important;
  }

  .filters-wrapper .sort-by-wrapper label {
    color: unset !important;
  }

  .card-block .flex-box-wrapper .experience-score-title {
    color: unset !important;
  }

  .item-wrapper .item-label {
    color: var(--cds-alias-typography-color-300) !important;
  }

  .slide-over {
    .slide-over-header {
      background-color: var(--cds-alias-object-container-background) !important;
      border-color: var(--cds-alias-object-border-color) !important;
    }
  }

  .sub-title {
    border-color: var(--cds-alias-object-border-color) !important;
  }

  .view-card-container {
    background-color: var(--clr-card-bg-color) !important;
    border-color: var(--clr-card-border-color) !important;

    .view-card-title {
      color: var(--cds-alias-typography-color-200) !important;
    }
  }

  .threshold-section:not(:last-child) {
    border-color: var(--cds-alias-object-border-color) !important;
  }

  .settings-container {
    background-color: unset !important;
    border-color: var(--cds-alias-object-border-color) !important;

    .platform-list {
      border-color: var(--cds-alias-object-border-color) !important;
    }

    .threshold-details .level {
      color: var(--cds-alias-typography-color-200) !important;
    }
  }
  // Experience Score -- end

  // Insights - start
  .welcome-text {
    color: unset !important;
  }

  .btn-row .btn-col-label {
    color: unset !important;
  }

  .btn-row .selectable-btn {
    background-color: var(--clr-card-bg-color) !important;
    border-color: var(--clr-card-border-color) !important;

    &.selected {
      background-color: var(--cds-alias-object-interaction-background-active) !important;
      border-color: var(--cds-alias-object-interaction-border-color) !important;
    }

    .btn-icon cds-icon.selected-icon {
      fill: unset !important;
    }

    .btn-title {
      color: unset !important;
    }
  }

  .search-row .search-container {
    background-color: var(--clr-forms-textarea-background-color) !important;
    border-color: var(--cds-alias-object-border-color) !important;

    input {
      background-color: unset !important;
    }
  }

  .insights-list-filter-component .form-footer {
    border-color: var(--cds-alias-object-border-color) !important;
  }

  .feed-wrapper {
    .list-view {
      .list-item {
        .insight-card {
          border-color: var(--clr-card-border-color) !important;
          background-color: var(--clr-card-bg-color) !important;
          box-shadow: var(--clr-card-box-shadow) !important;

          .insight-name {
            color: unset !important;
          }

          .impacted-text {
            color: var(--cds-alias-typography-color-200) !important;
          }

          &:hover {
            background-color: var(--cds-alias-object-interaction-background-active) !important;
          }

          .expand-button {
            background: var(--cds-global-color-construction-700) !important;

            cds-icon[shape='angle'] {
              color: var(--cds-global-color-construction-200) !important;
            }
          }

          .date-icons-wrapper .date-text {
            color: var(--cds-alias-typography-color-200) !important;
          }
        }

        .insight-detail {
          border-color: var(--clr-card-border-color) !important;
        }

        dpa-timeline {
          .breadcrumb-time {
            color: var(--cds-alias-typography-color-200) !important;
          }
          .breadcrumb-description {
            color: var(--cds-alias-typography-color-300) !important;
          }

          .separator {
            background-color: unset !important;
          }
        }

        .add-comment-header {
          color: unset !important;
        }

        .incident-name {
          color: var(--clr-forms-label-color) !important;
          font-weight: var(--clr-forms-label-font-weight) !important;
        }
      }
    }
  }

  // Insights - End

  // Investigations - start
  dpa-deem-selected-incident-header {
    background: unset !important;
    border-color: var(--cds-alias-object-border-color) !important;
  }

  dpa-deem-rca-preview-results-list {
    .preview-wrapper {
      background-color: unset !important;

      .top-row .top-results-text {
        color: unset !important;
      }
    }
  }

  .card-block.rca-bottom-container {
    background-color: unset !important;
  }

  .core-label {
    color: unset !important;
  }

  .rca-page .results-summary .results-summary-text {
    color: unset !important;
  }

  .summary-wrapper .info-row .summary-info-wrapper .summary-category {
    color: var(--cds-alias-typography-color-300) !important;
  }

  .results-header {
    color: var(--cds-alias-typography-color-300) !important;
  }

  .comment-container {
    border-color: var(--cds-alias-object-border-color) !important;
  }

  dpa-incidents-dashboard {
    .separator {
      background: none !important;
      border-color: var(--cds-alias-object-border-color) !important;
    }
  }

  dpa-deem-incident-comments {
    quill-editor + .actions {
      border-color: var(--cds-alias-object-border-color) !important;
    }
  }

  .results-summary-text {
    color: unset !important;
  }

  // Investigations - End

  .ql-snow .ql-fill,
  .ql-snow .ql-stroke.ql-fill {
    fill: var(--cds-alias-typography-color-200) !important;
  }

  .ql-snow .ql-stroke {
    stroke: var(--cds-alias-typography-color-200) !important;
  }

  // SAM - start
  .sticky-header-tabs .tab-data {
    color: var(--cds-alias-typography-color-200) !important;
  }

  dpa-sticky-header-v2 {
    dpa-custom-tag {
      .banner-wrapper::after {
        border-right-color: var(--clr-card-bg-color) !important;
      }
    }
  }

  dpa-deem-sam-filter {
    .filter-container {
      border-color: var(--clr-card-border-color) !important;
      background: var(--clr-card-bg-color) !important;

      .row-container {
        background: unset !important;
      }

      .filter-item-container .filter-icon-container {
        background: unset !important;

        .icon-title {
          color: unset !important;
        }
      }
    }
  }

  // SAM - end

  // HubNotifications - start
  dpa-deem-hub-notification-creation-counters-widget,
  dpa-deem-hub-notification-actions-counters-widget {
    dpa-metric-card {
      .metric-card {
        color: var(--cds-alias-typography-color-300) !important;
      }
    }
  }

  .notification-detail-header {
    background: var(--cds-alias-object-container-background) !important;
    border-bottom-color: var(--cds-alias-object-border-color) !important;
  }

  dpa-deem-hub-notification-detail-counters {
    .card .card-body {
      color: var(--cds-alias-typography-color-300) !important;

      .date-container .date-value {
        color: var(--cds-alias-typography-color-300) !important;
      }
    }
  }

  .list-item-wrapper {
    .sent-date-wrapper {
      color: var(--cds-alias-typography-color-200) !important;
    }

    .notification-type-wrapper .informational {
      background: var(--clr-badge-background-color) !important;
    }
  }

  // HubNotifications - end

  // Survey - start
  dpa-deem-create-survey {
    .sticky-header-page-wrapper {
      background-color: var(--clr-global-app-background) !important;
    }
  }

  .review-prereq {
    background-color: var(--cds-alias-object-container-background) !important;
    border-color: var(--cds-alias-object-border-color) !important;

    .review-prereq-header,
    .authorized {
      color: unset !important;
    }
  }

  .step-card-container .step-card {
    background: var(--cds-alias-object-container-background) !important;
    border-color: var(--cds-alias-object-border-color) !important;
  }

  dpa-deem-survey-preview {
    border-color: var(--cds-alias-object-border-color) !important;

    .preview-container .preview-body {
      background: unset !important;

      .preview-card {
        background-color: var(--clr-card-bg-color) !important;
        border: var(--clr-card-border-color) !important;
        box-shadow: var(--clr-card-box-shadow) !important;

        h5 {
          color: unset !important;
        }
      }
    }
  }

  .survey-detail-selected-component .survey-detail-header {
    background-color: var(--cds-alias-object-container-background) !important;
  }

  // Survey - end

  // Synthetic URL Monitoring - start
  .main-container .content-area dpa-alert-banner {
    background: unset !important;
  }

  dpa-synthetic-url-monitoring-config-builder {
    h3 {
      color: unset !important;
    }
  }
  // Synthetic URL Monitoring - end

  // Horizon Cloud - Next Gen - start
  .tabs-container .tab {
    .tab-name {
      color: var(--cds-alias-typography-color-200) !important;
    }
    &.selected {
      .tab-name {
        color: var(--cds-alias-typography-color-300) !important;
      }
    }
  }

  // Horizon Cloud - Next Gen - start

  // Security Risk - start

  .sticky-header-tabs.minimized .tab-data > * {
    border-color: var(--cds-alias-object-border-color) !important;
    background: var(--cds-alias-object-container-background) !important;
  }

  // Security Risk - end

  // Workspace Security - Settings - start

  .multi-range-slider-container {
    background-color: var(--clr-card-bg-color) !important;
  }

  .multi-range-slider .value-thumb {
    color: unset !important;
  }

  // Workspace Security - Settings - end

  // MarketPlace - explore - start
  dpa-marketplace-explore {
    .bg-container {
      background: var(--clr-global-app-background) !important;
    }
  }

  dpa-marketplace-explore-template-card {
    .template-card {
      border-color: var(--clr-card-border-color) !important;
      background-color: var(--clr-card-bg-color) !important;
      box-shadow: var(--clr-card-box-shadow) !important;

      .template-card-header h5 {
        color: unset !important;
      }

      .card-footer {
        border-color: var(--cds-alias-object-border-color) !important;
      }
    }
  }

  .templates-container {
    border-color: var(--cds-alias-object-border-color) !important;
    .template-explore {
      color: unset !important;
    }
  }

  // MarketPlace - explore - end

  // MarketPlace - Template Preview - start
  dpa-marketplace-dashboard-template-preview {
    .prereq-container {
      background-color: var(--cds-alias-object-container-background) !important;
    }
  }

  .banner-wrapper::after {
    border-right-color: var(--cds-alias-object-container-background-dark) !important;
  }

  .featured-banner {
    border-color: var(--cds-alias-object-border-color) !important;
    background-color: var(--cds-alias-object-container-background) !important;
  }

  .container .filter-tag {
    color: var(--cds-alias-typography-color-200) !important;
    border-color: var(--cds-alias-object-border-color) !important;
  }

  clr-dg-cell {
    .banner-wrapper::after {
      border-right-color: var(--cds-alias-object-container-background) !important;
    }
  }

  // MarketPlace - Template Preview - end

  // Workspace One - start
  //dpa-integration-card, dpa-connector-card,
  //dpa-connection-card, dpa-partner-connection-card,
  //dpa-event-forwarder-connection-card {
  .dpa-card-header {
    color: var(--cds-alias-typography-color-300) !important;
  }
  // }
  // Workspace One - end

  // Experience Management -- start
  h2.deem-overview-titile,
  h2.submodule-title {
    color: unset !important;
  }

  .learn-more-tabs .sticky-tabs {
    background: var(--cds-alias-object-container-background) !important;
    border-color: var(--cds-alias-object-border-color) !important;
    .tab:not(.active) {
      border-color: var(--cds-alias-object-border-color) !important;
    }
    .tab.active {
      color: unset !important;
    }
  }

  .learn-more-footer {
    background: unset !important;
    border-color: var(--cds-alias-object-border-color) !important;

    h4 {
      color: unset !important;
    }

    .footer-cards {
      .link-card {
        background: var(--clr-card-bg-color) !important;
        border-color: var(--clr-card-border-color) !important;
        box-shadow: var(--clr-card-box-shadow) !important;
      }

      .submodule-title {
        color: unset !important;
      }
    }
  }

  // Experience Management -- end

  // Vulnerability Management - start
  dpa-cve-solution-overview {
    section.near-white {
      background-color: var(--clr-card-bg-color) !important;
    }
  }

  .link-box[_ngcontent-ng-c1346684497] {
    background-color: var(--clr-card-bg-color) !important;

    .link-container a {
      background: var(--cds-global-color-construction-800) !important;
      border: var(--cds-alias-object-border-color) !important;
    }
  }
  // Vulnerability Management - end

  // Workflows - start
  dpa-automation-template-actions-preview {
    ol {
      li {
        background: var(--clr-card-bg-color) !important;
        border-color: var(--cds-alias-object-border-color) !important;

        .trigger-action-details {
          color: var(--cds-alias-typography-color-200) !important;
        }
      }
    }
  }

  .slide-over .slide-over-footer {
    border-color: var(--cds-alias-object-border-color) !important;
  }
  // Workflows - start

  // Scripts - start
  dpa-marketplace-script-template-preview,
  dpa-marketplace-sensor-template-preview {
    .prereq-container {
      background-color: var(--clr-card-bg-color) !important;
    }
  }
  // Scripts - end

  // SystemLimits - start
  .usage-card-cntr {
    background: unset !important;
    border-color: var(--cds-alias-object-border-color) !important;

    .separator {
      border-color: var(--cds-alias-object-border-color) !important;
    }
  }

  .usage-item-comparision {
    color: unset !important;
  }
  // SystemLimits - end

  dpa-card {
    .banner-wrapper::after {
      border-right-color: var(--clr-card-bg-color) !important;
    }
  }

  .home-page-section-item {
    dpa-getting-started-home-section {
      .panel.expanded {
        background: unset !important;
      }
    }
  }

  .dpa-card .dpa-card-footer {
    border-color: var(--cds-alias-object-border-color) !important;
  }

  dpa-deem-hub-notification-creation-counters-widget,
  dpa-deem-hub-notification-actions-counters-widget,
  dpa-deem-hub-notification-detail-counters {
    .vertical-line {
      border-color: var(--cds-alias-object-border-color) !important;
    }
  }

  // Data Explorer - Start
  dpa-widget-builder {
    .categories {
      border-color: var(--cds-alias-object-border-color) !important;
      .category {
        border-color: var(--cds-alias-object-border-color) !important;

        &.active {
          background-color: var(--cds-global-color-construction-800) !important;
        }
      }
    }
  }

  dpa-widget-main-form {
    .form-container {
      background-color: var(--cds-alias-object-container-background) !important;
    }
  }
  // Data Explorer - End

  // Freestyle - Start
  .save-draft-modal .modal-footer,
  .edit-confirmation-modal .modal-footer {
    border-color: var(--cds-alias-object-border-color) !important;
  }

  dpa-type-ahead-multi {
    .loading-spinner-overlay {
      background: unset !important;
    }
  }

  .rule-text-wrapper {
    .values-wrapper {
      background: unset !important;
      border-color: var(--cds-alias-object-border-color) !important;
    }
  }
  // Freestyle - End

  dpa-deem-expandable-card {
    .control-button {
      background: var(--cds-global-color-construction-700) !important;

      cds-icon {
        color: var(--cds-alias-typography-color-200) !important;
      }
    }
  }

  .home-page-content {
    .section-drag-wrapper {
      background-color: var(--clr-card-bg-color) !important;
    }
    .cdk-drop-list {
      &.cdk-drop-list-dragging {
        .cdk-drag,
        .cdk-drag-placeholder {
          background: var(--clr-card-bg-color) !important;
        }

        .section-drag-item-container {
          border-bottom: none;
        }

        .section-title {
          color: var(--clr-card-text-color) !important;
        }
      }
    }
  }
}
