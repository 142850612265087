/*
 * Copyright 2019 VMware, Inc.
 * All rights reserved.
 */

@use 'sass:math';

// Clone from Clarity code base
$dpa-baseline-int: 24 !default; // used by design for relational sizing
$dpa-baseline-denominator: 20 !default; // used in code to reduce impact of browser rounding

$rem-ratio: math.div($dpa-baseline-int, $dpa-baseline-denominator);

// The value of x is based on the UX mockups where x=6px=0.25rem
$x-scale-value: 0.25; // 0.25rem => 6px

/**
 * remToBase
 *
 * This function converts existing rem value in based 24 (baseline-int) to new base line (baseline-denominator)
 * eg.
 * - remToBase(0.5) -> 0.6rem
 *
 * Usage:
 * - margin: x(1) x(2); -> margin: 0.25rem 0.5rem;
 */
@function remToBase($n) {
  @return $n * $rem-ratio * 1rem;
}

/**
 * x(n)
 * This function converts a 6px multiple into a rem value
 * eg.
 * - x(1) -> 0.25rem (6px)
 * - x(4) -> 1rem (24px)
 * - x(1/3) -> 0.083rem (2px)
 * - x(-3.5) -> -0.875rem (-21px)
 *
 * Usage:
 * - margin: x(1) x(2); -> margin: 0.25rem 0.5rem;
 */
@function x($n) {
  @return $n * $x-scale-value * $rem-ratio * 1rem;
}

/**
 * px(n)
 * This function converts a pixel value into an rem value
 * eg.
 * - px(24) -> 1.2rem (24px)
 * - px(1)  -> 0.05rem (1px)
 *
 * Usage:
 * - border-bottom: px(1) solid $clr-gray;
 */
@function px($n) {
  @return math.div($n, $dpa-baseline-int) * $rem-ratio * 1rem;
}

/**
 * Shortcut variables
 */
$x1: x(1);
$x2: x(2);
$x3: x(3);
$x4: x(4);
$x5: x(5);
$x6: x(6);
$x7: x(7);
$x8: x(8);
$x9: x(9);
$x10: x(10);
$px1: px(1);
$px2: px(2);
