/*
 * Copyright 2022 VMware, Inc.
 * All rights reserved.
 */

@import 'variables';
@import 'z-index';

.datagrid-row .datagrid-select .clr-checkbox-wrapper {
  margin-top: 0;
}

// enable last column in the table to resize
.datagrid-row-scrollable .datagrid-column:last-child .datagrid-column-separator {
  display: flex;
  opacity: 0;
  left: px(1);
}

// remove checkbox/radio button column when the complete grid is not selectable
.notselectable .datagrid-select {
  display: none;
}

// hide Clarity's column toggle
dpa-data-grid .datagrid-footer .column-switch-wrapper {
  display: none;
}

// center the spinner shown for delay render
dpa-data-grid-column-selector .column-list > dpa-delay-render-placeholder {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}

dpa-data-grid-column-selector {
  .available-columns .search-results b,
  .selected-columns .search-result b {
    color: $dpa-fuzzy-match-color;
  }
}

dpa-data-grid-column-selector .available-columns .tree-view-wrapper {
  padding-top: 0;
  .clr-tree-node-content-container {
    height: px(32);
    .category-item {
      font-weight: bold;
    }
    &:hover,
    &:focus,
    &:focus-within,
    &:focus-visible {
      .item-selected {
        display: none;
      }
      .item-remove {
        display: inline-block;
      }
    }
  }
}

dpa-data-grid-column-selector .selected-columns .list-group-container {
  .cdk-drag-preview {
    &.list-group-item {
      border: px(2) dashed var(--cds-alias-object-border-color);
    }
  }
  .cdk-drag-placeholder {
    opacity: 0;
  }
  .cdk-drag-animating {
    transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
  }
  &.cdk-drop-list-dragging &:not(.cdk-drag-placeholder) {
    transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
  }
}

dpa-data-grid-column-selector-dialog {
  .modal-content {
    padding: 0 !important;

    > div {
      padding-inline: var(--cds-global-space-9);

      &:first-child {
        padding-top: var(--cds-global-space-9);
        border-bottom: px(1) solid var(--cds-alias-object-border-color);
      }

      &:last-child {
        padding-bottom: var(--cds-global-space-9);
        border-top: px(1) solid var(--cds-alias-object-border-color);
      }
    }
  }
}
